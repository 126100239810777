<template>
  <v-col cols="12" class="">
    <label style="font-size: 16px">Nhập audio (định dạng mp3)</label>
    <p>
      <span style="color: red"
        >(Sẽ mất khoảng 5 - 7 phút để xử lí file khi transcript)</span
      >
    </p>
    <div class="d-flex mb-2">
      <b-form-file
        @change="onFileChange"
        placeholder="Chọn tệp tin hoặc cancel để xóa"
        accept=".mp3"
      ></b-form-file>
      <v-btn class="ml-2" outlined color="cyan" @click="transcriptAudio">
        <v-icon>mdi-script-text</v-icon>
        Transcript
      </v-btn>
    </div>
    <template v-if="karaoke_data != null && data_transcript.words.length > 0">
      <!-- <Karaoke :file="karaoke_data" :lyrics="data_transcript.words"></Karaoke> -->
      <Karaoke :file="karaoke_data" :lyrics="data_transcript.words"></Karaoke>
    </template>
  </v-col>
</template>

<script>
import ApiService from "@/service/api.service";
export default {
  name: "Shadowing",
  components: {
    Karaoke: () => import("@/components/Audio/vuetifly-karaoke.vue"),
  },
  props: {
    karaoke_file_url: {
      type: String,
      default: null,
    },
    transcript_audio_data: {
      type: Object,
      default: function () {
        return {
          transcript: [],
          words: [],
        };
      },
    },
  },
  data() {
    return {
      // karaoke_data: null,
      karaoke_file: null,
      // data_transcript: {
      //   transcript: [],
      //   words: [],
      // },
    };
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    karaoke_data: {
      get() {
        return this.karaoke_file_url;
      },
      set(value) {
        this.$emit("setSampleData", value);
      },
    },
    data_transcript: {
      get() {
        return this.transcript_audio_data;
      },
      set(value) {
        this.$emit("setTranscriptAudio", value);
      },
    },
  },
  watch: {
    karaoke_file_url(val) {
      this.karaoke_data = val;
    },
    // karaoke_data(value) {
    //   this.$emit('setSampleData', value);
    // },
    // data_transcript(val) {
    //   this.$emit('setTranscriptAudio', val);
    // },
  },
  methods: {
    onFileChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.karaoke_data = null;
        vm.karaoke_file = null;
        return;
      }
      vm.karaoke_file = file;
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.karaoke_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async transcriptAudio() {
      // validate file .....
      if (this.karaoke_file != null) {
        if (!this.karaoke_file.type.match("audio/mpeg")) {
          this.$toasted.error("File audio phải có định dạng là mp3 !", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          return;
        }
      }

      let vm = this;
      let formData = new FormData();
      formData.append("file", this.karaoke_file);
      vm.is_call_api = true;
      await ApiService.post(
        "prep-app/test/draft/question/transcript-audio",
        formData
      )
        .then(function (response) {
          vm.data_transcript = response.data;
          vm.karaoke_data = response.data.file_name;
          vm.is_call_api = false;
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            vm.$toasted.error(
              "Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
